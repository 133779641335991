import React, { useContext } from "react"
import {
  Box,
  /*
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  */
  Stack,
  Text,
  Grid,
  Flex,
  Container,
  Link,
  Button,
  Heading,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  useColorModeValue,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link as GatsbyLink } from "gatsby"
import { slugify } from "../utils/slugify"
import Hero from "../components/hero"
import { transparentize, tint } from "polished"
import { LocalizationContext } from "../context/LocalizationContext"
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons"

const TourDayPage = ({ data, pageContext }) => {
  const tour = data.wpTour
  const title = pageContext.title
  const seoTitle = `${tour.title} - ${title}`
  const language = pageContext.language
  const locale =
    language.toLowerCase() === "en" || language.toLowerCase() === "zh"
      ? "/"
      : `/${language.toLowerCase()}/`
  const { translate } = useContext(LocalizationContext)

  const schedule = tour.tourBuilder.schedule
  let currentDay = 0
  let currentTourDay = []
  schedule.map((x, key) => {
    if (x.title === title) {
      currentDay = key
      currentTourDay = x
    }
  })
  const featuredImage = currentTourDay.image

  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <Layout language={language} overlayHeader={true}>
      <SEO title={seoTitle} lang={language} />

      <Hero
        image={featuredImage.localFile}
        title={<Box mb={4}>{currentTourDay.subtitle}</Box>}
        subTitle={
          <Menu isLazy placement="bottom">
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="lg">
              {currentTourDay.title}
            </MenuButton>
            <MenuList color={colorMode === "light" ? "white" : "text"}>
              <MenuGroup title={tour.title}>
                {tour.tourBuilder.schedule.map((x, j) => (
                  <MenuItem
                    key={j}
                    as={GatsbyLink}
                    to={`${locale}${tour.slug}/${slugify(x.title)}`}
                    fontSize="md"
                  >
                    {x.title}
                  </MenuItem>
                ))}
              </MenuGroup>
            </MenuList>
          </Menu>
        }
      />
      {/**
      <Breadcrumb
        fontSize="sm"
        colorScheme="gray"
        spacing={1}
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={GatsbyLink} to={locale}>
            Xwander Nordic
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={GatsbyLink} to={`${locale}${tour.slug}`}>
            {tour.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      */}
      <Container maxWidth="5xl" mt={[12, 24]}>
        <Stack spacing={[8, 16]}>
          {featuredImage.description && (
            <Text
              fontSize={{ md: "lg", lg: "xl" }}
              dangerouslySetInnerHTML={{ __html: featuredImage.description }}
            />
          )}

          <Box
            fontSize={{ md: "lg", lg: "xl" }}
            dangerouslySetInnerHTML={{ __html: currentTourDay.body }}
          />

          {(currentTourDay.additionalInformation !== null ||
            currentTourDay.hotels !== null) && (
            <Box bg="gray.200" color="text" p={[4, 8]}>
              {currentTourDay.additionalInformation && (
                <Box>
                  <Heading mb={4} size="md">
                    {translate("tour.additionalInfo")}
                  </Heading>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: currentTourDay.additionalInformation,
                    }}
                  ></Box>
                </Box>
              )}

              {currentTourDay.hotels && (
                <Box>
                  <Heading mt={6} mb={4} size="md">
                    Accomodation
                  </Heading>
                  <Box as="ul" sx={{ listStyle: "disc", pl: 8, mb: 0 }}>
                    {currentTourDay.hotels.map((hotel, h) => (
                      <li key={h}>
                        <Link
                          href={hotel.hotel.url}
                          target="_blank"
                          sx={{
                            color:
                              currentTourDay.image.localFile.colors.darkVibrant,
                            ":hover": { opacity: 0.8 },
                          }}
                        >
                          {hotel.hotel.title}
                        </Link>
                      </li>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {currentTourDay.gallery && (
            <Grid
              gridTemplateColumns={[
                "repeat(auto-fit, minmax(300px, 1fr))",
                null,
                "repeat(auto-fit, minmax(400px, 1fr))",
              ]}
              gridGap={4}
            >
              {currentTourDay.gallery.map((image, g) => (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  key={g}
                  alt={image.altText}
                  sx={{
                    flex: [
                      "none",
                      null,
                      image.localFile.childImageSharp.fluid.aspectRatio,
                    ],
                    width: "100%",
                  }}
                />
              ))}
            </Grid>
          )}

          <Flex justifyContent="space-between">
            <Button as={GatsbyLink} to={`${locale}${tour.slug}`}>
              &larr; {tour.title}
            </Button>
            {currentDay + 1 < schedule.length && (
              <Button
                as={GatsbyLink}
                to={`${locale}${tour.slug}/${slugify(
                  schedule[currentDay + 1].title
                )}`}
              >
                {schedule[currentDay + 1].title} &rarr;
              </Button>
            )}
          </Flex>
        </Stack>
      </Container>
    </Layout>
  )
}
export default TourDayPage

export const pageQuery = graphql`
  query($id: String) {
    wpTour(id: { eq: $id }) {
      id
      slug
      title
      language {
        code
      }
      tourBuilder {
        subtitle
        description
        schedule {
          body
          excerpt
          fieldGroupName
          subtitle
          title
          additionalInformation
          image {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 60) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
              colors {
                vibrant
                darkVibrant
                lightVibrant
                muted
                darkMuted
                lightMuted
              }
            }
          }
          hotels {
            hotel {
              title
              url
            }
          }
          gallery {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 60) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
            colors {
              vibrant
              darkVibrant
              lightVibrant
              muted
              darkMuted
              lightMuted
            }
          }
        }
      }
    }
  }
`
